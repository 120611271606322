<template>
  <div>
    <DataTable
      v-model:filters="filters"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      row-hover
      paginator
      scrollable
      filterDisplay="row"
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column
        dataType="date"
        filter-field="pi_between"
        field="pi_at"
        header="Tanggal"
        style="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.pi_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="pi_no"
        field="pi_no"
        header="No Bukti"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="inv_no"
        field="inv_no"
        header="Faktur"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="supplier"
        field="supplier.nama"
        header="Nama Supplier"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Jumlah Beli"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.total_pembelian) }}
        </template>
      </Column>
      <Column
        field="diskon"
        header="Diskon"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.diskon) }}
        </template>
      </Column>
      <Column
        field="ppn"
        header="PPN"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.ppn_pi) }}
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Total"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column
        field="pajak_no"
        header="No Faktur Pajak"
        style="min-width: 12rem"
      />
      <Column
        field="dpp_pi"
        header="DPP Faktur Pajak"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp_pi) }}
        </template>
      </Column>
      <Column
        field="ppn_pi"
        header="PPN Faktur Pajak"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.ppn_pi) }}
        </template>
      </Column>
      <Column field="updated_at" header="Tgl Lunas" style="min-width: 12rem">
        <template #body="{ data }">
          {{ formatDate(data.date_paid) }}
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Jumlah Lunas"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column
        field="jumlah_total"
        header="Jumlah Dibayar"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah_total) }}
        </template>
      </Column>
      <Column
        field="cn"
        header="CN"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.cn) }}
        </template>
      </Column>
      <Column
        field="dn"
        header="DN"
        style="min-width: 10rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dn) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import { FilterMatchMode } from 'primevue/api'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  components: {
    CalendarFilter,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      filters: {
        pi_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pi_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inv_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
